.B2B .body {
  width: 100%;
  display: flex;
  min-height: 0;
  flex: 1;
}

.B2B .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.B2B .selectedCont {
  display: flex;
  min-height: 0;
  flex-shrink: 0;
  background-color: #f5f5f5;
  max-height: 400px;
  overflow-y: auto;
  justify-content: center;
  align-items: stretch;
  margin-top: 5px;
}

/* .B2B .gridContainer button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: none;
  background-color: #f5f5f5;
  padding: 2px 5px;
} */

.B2B .DemandDetail,
.WorkDetail {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: solid 1px #ccc;
  display: flex;
  flex-direction: column;
}

.WorkDetail .topCont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.WorkDetail .gantcont {
  flex: 1;
  min-height: 0;
  background-color: white;
}

.WorkDetail .actionsCont {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.WorkDetail .actionsCont > * > * {
  min-height: 30px;
}

.WorkDetail .actionsCont button {
  line-height: 1;
}

.WORKGANTROW button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: none;
}

.WORKGANTROW .label {
  font-size: 14px;
}

.WORKGANTTABLE {
  background-color: rgb(224, 224, 224);
}

.WorkDetail .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
