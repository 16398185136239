.SalesOrdersPage {
  display: flex;
  flex-direction: column;
}

.SalesOrdersPage .ribbon {
  min-height: 1px;
  flex-shrink: 0;
}

.SalesOrdersPage .body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.SalesOrdersPage .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.SalesOrders.DetailsCont .head {
  padding: 10px;
  background-color: #f5f5f5;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.SalesOrders.DetailsCont .body {
  padding: 10px;
}

.SalesOrders.DetailsCont .body > div {
  margin-bottom: 10px;
}

.SalesOrders.DetailsCont .body .headr {
  text-decoration: underline;
}
