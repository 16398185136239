.WorkPlannerPage {
  display: flex;
  flex-direction: column;
}

.WorkPlannerPage .ribbon {
  min-height: 1px;
  flex-shrink: 0;
}

.WorkPlannerPage .body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.WorkPlannerPage .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.CRUDCategories {
  width: 100vw;
  max-width: 100%;
}

.CRUDCategories .new {
  max-width: 300px;
  margin-top: 20px;
}

.CRUDCategories .roww {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.CRUDCategories .roww .value {
  flex: 1;
}

.CRUDCategories .sortedList {
  margin: 20px 0;
}

.CRUDCategories .sortedList .cat {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.CRUDCategories .sortedList .cat button {
  font-size: 12px;
  border: none;
  padding: 3px;
}

.CRUDCategories .sortedList .cat button.save {
  background-color: #0f0;
}

.CRUDCategories .sortedList .name {
  width: 30%;
}

.CRUDCategories .sortedList .description {
  flex: 1;
}
