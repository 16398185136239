.DataLog {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.DataLog .DataLogHeader {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.DataLog .BlockDetailCont {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  background-color: white;
  z-index: 1000;
  border-left: 1px solid rgb(238, 235, 235);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

.DataLog .ListCont {
  flex: 1;
  min-height: 0;
  overflow: auto;
  font-size: 14px;
}

.DataLogNode {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.DataLogNode .DataLogNodeHeader {
  cursor: pointer;
  padding: 5px;
  user-select: none;
  display: flex;
  gap: 10px;
}

.DataLogNode .DataLogNodeHeader .basic {
  width: 100%;
  max-width: 300px;
}

.DataLogNode .DataLogNodeHeader .snippet {
  flex: 1;
}

.DataLogNode .detailCont {
  padding: 5px;
}

.DataLogNode .comments {
  font-style: italic;
}

@media (max-width: 600px) {
  .DataLogNode .DataLogNodeHeader {
    flex-direction: column;
  }
  .DataLogNode .DataLogNodeHeader .basic {
    width: 100%;
    max-width: none;
  }
}
