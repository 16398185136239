.GRMultiYearPage .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.GRMultiYearPage .GridCont {
  max-width: 1100px;
}

.GRMultiYearPage .GridCont table {
  width: 100%;
  font-size: 14px;
  position: relative;
}

.GRMultiYearPage .GridCont table tr td {
  border: 1px solid #bbbbbb;
}

.GRMultiYearPage .GridCont table thead tr th {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

.GRMultiYearPage .GridCont table thead tr th.yearCol {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  line-height: 1.2;
}

.GRMultiYearPage .GridCont table thead tr th.yearCol .year {
  font-weight: bold;
  text-align: center;
}

.GRMultiYearPage .GridCont .yearCol {
  text-align: center;
  width: 80px;
  min-width: 80px;
}

.GRMultiYearPage .GridCont .blocksCol > div {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.GRMultiYearPage .GridCont .blocksCol button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 1;
  background-color: white;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
}

.GRMultiYearPage .GridCont table tbody tr:hover {
  background-color: #f5f5f5;
}

.GRMultiYearPage .GridCont .yearCol:hover {
  background-color: #03e2ff;
  cursor: pointer;
}

.GRMultiYearPage .GridCont .yearCol.past {
  background-color: #d4d4d4;
}

.GRMultiYearPage .GridCont .yearCol.current {
  background-color: #c8ffcb;
}

.GRMultiYearPage .GridCont .yearCol.future {
  background-color: #f6edff;
}

.GRMultiYearPage .BlockDetailCont {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  background-color: white;
  z-index: 1000;
  border-left: 1px solid rgb(238, 235, 235);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}
