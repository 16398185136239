.GRribbon.ribbon {
  padding: 0;
}

.ProgramEditor {
  padding: 10px;
}

.ProgramEditor table {
  width: 100%;
}

.ProgramEditor table thead th {
  text-align: center;
}

.ProgramEditor table tbody td {
  text-align: center;
  padding: 0 5px;
}

.ProgramEditor table input[type="text"] {
  border: none;
  background-color: lightgray;
  width: 100%;
}

.ProgramEditor table input[type="checkbox"] {
  cursor: pointer;
}

.ProgramEditor table input[type="text"].minwidth {
  max-width: 100px;
}

.mapAndGridAndDetailContainer {
  width: 100%;
  display: flex;
  min-height: 0;
  flex: 1;
}

.mapAndGridContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-right: 0px;
}

.mapAndGridContainer.filteredBlock {
  flex: 0.7;
  width: calc(100% - 500px);
  padding-right: 5px;
}

.blockDetailContainer {
  height: 100%;
  width: 0px;
  min-width: 0px;
  flex: 0;
  background-color: white;
  overflow: auto;
}

.blockDetailContainer.shown {
  flex: 1;
  width: 100%;
  max-width: 500px;
  border-left: 1px solid rgb(238, 235, 235);
  border-top: 1px solid rgb(238, 235, 235);
}

.MuiDataGrid-footerContainer {
  min-height: 32px !important;
}

.blockDetailContainer .blockDetailRow {
  display: flex;
  font-size: 14px;
}

.blockDetailContainer .blockDetailRow .label {
  min-width: 120px;
  margin-right: 10px;
  color: gray;
}

.blockDetailContainer .blockDetailRow.boundary .label {
  color: blue;
}

.blockDetailContainer .section {
  margin: 30px 0;
  border-top: solid 1px rgb(238, 235, 235);
  border-bottom: solid 1px rgb(238, 235, 235);
  padding: 10px 0;
}

.blockDetailContainer .section h5 {
  font-size: 16px;
}

.blockDetailContainer .section .ttt {
  padding: 0 10px;
}

.kt {
  width: 100%;
}

.kt .type {
  font-size: 14px;
  font-weight: bold;
}

.kt .tasks {
  font-size: 12px;
}

.kt .task {
  margin-bottom: 10px;
  border-bottom: 3px solid rgb(238, 235, 235);
  padding-bottom: 10px;
}

.kt .task .comments {
  word-wrap: normal;
}

.kt .images {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  align-items: flex-start;
}

.kt .images img {
  width: 40px;
  height: auto;
  cursor: pointer;
}

.kt .dataCont {
  margin-bottom: 10px;
  width: 100%;
}

.kt .dataCont .tabContent {
  max-height: 200px;
  overflow: auto;
  flex: 1;
}

.kt .dataCont .task {
  padding: 10px;
}

.kt .dataCont .task .title {
  font-weight: bold;
}

.BlockDetail .ProgramEditor {
  font-size: 0.8em;
  padding: 0 10px;
}

.ribbon .totalscont {
  font-size: 12px;
}

@media (max-width: 600px) {
  .mapAndGridAndDetailContainer.filteredBlock {
    flex-direction: column;
  }

  .mapAndGridAndDetailContainer.filteredBlock .gridContainer {
    display: none;
    height: 0;
    width: 0;
  }

  .mapAndGridContainer.filteredBlock {
    padding-right: 0;
    width: 100%;
    flex: 0;
  }

  .mapView .mapAndGridContainer.filteredBlock {
    flex: 0.7;
  }

  .blockDetailContainer.shown {
    flex: 1;
    width: 100%;
    max-width: none;
  }

  .hidemobile {
    display: none !important;
  }
}
