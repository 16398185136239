.GRSchedule .cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.GRSchedule .tableCont {
  overflow: auto;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #f7f7f7;
}

.GRSchedule .ScheduleGrid table {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  font-weight: 400;
  scrollbar-width: thin;
}

.GRSchedule .ScheduleGrid table td,
.GRSchedule .ScheduleGrid table th {
  border: 1px solid rgb(189, 188, 188);
  font-weight: 400;
}

.GRSchedule .ScheduleGrid table .isDetailed td {
  border: 1px solid blue;
}

.GRSchedule .ScheduleGrid table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  background-color: rgb(228, 227, 227);
}

.GRSchedule .ScheduleGrid table td {
  background: #fff;
}

.GRSchedule .ScheduleGrid table tbody th {
  position: relative;
}

.GRSchedule .ScheduleGrid table tbody th,
.GRSchedule .ScheduleGrid table tbody td {
  height: 34px;
}

.GRSchedule .ScheduleGrid table tbody .groupHeaderRow th,
.GRSchedule .ScheduleGrid table tbody .groupHeaderRow td {
  height: auto;
}

.GRSchedule .ScheduleGrid table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.GRSchedule .ScheduleGrid table th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  min-width: 400px;
  width: 400px;
  max-width: 400px;
  box-shadow: 4px 0 4px 0 rgb(97, 97, 97);
  padding: 0;
  overflow: hidden;
}

.GRSchedule .dateCell {
  width: 60px;
  min-width: 60px;
  padding: 0;
  outline: none;
  user-select: none !important;
}

.GRSchedule .dateCell .sortIndicator {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.GRSchedule th.dateCell > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2px 0;
}
.GRSchedule th.hasUnsavedChanges {
  background-color: rgb(207, 243, 6) !important;
}
.GRSchedule .gridCell {
  position: relative;
}

.GRSchedule th.dateCell {
  font-size: 10px;
  line-height: 1;
}

.GRSchedule th.dateCell .date {
  font-size: 12px;
}

.GRSchedule .groupHeaderRow .description {
  font-size: 12px;
  font-weight: bold;
}

.GRSchedule .groupHeaderRow * {
  background-color: rgb(151, 151, 151) !important ;
}

.GRSchedule .groupHeaderRow * {
  color: white !important;
}

.GRSchedule .dateCell input,
.GRSchedule .dateCell .delCont {
  text-align: center;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: center;
  display: block;
  user-select: none !important;
}

.GRSchedule .dateCell .delCont {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.GRSchedule .dateSelector {
  display: flex;
  align-items: center;
}

.GRSchedule .dateSelector .dateLabel {
  width: 80px;
  text-align: center;
}

.GRSchedule .isWeekend * {
  background: rgb(255, 214, 176);
}

.GRSchedule .isPast * {
  background: rgb(207, 207, 207);
}

.GRSchedule .isPast.isWeekend * {
  background: rgb(156, 156, 156);
}

.GRSchedule .isToday * {
  background: #d4e9ff;
}

.GRSchedule .isChanged *,
.GRSchedule *.isChanged {
  background: rgb(207, 243, 6) !important;
}

.blockCellCont {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}

.blockCellCont .blockAttributes {
  padding: 0 2px;
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-right: 3px;
  font-size: 10px;
  flex-direction: column-reverse;
  cursor: pointer;
}

.blockCellCont .blockAttributes > div {
  display: flex;
  align-items: center;
}

.blockCellCont .blockStats {
  display: flex;
  font-size: 10px;
}

.blockCellCont .blockStats > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;
}

.blockCellCont .blockStats > div.unsaved {
  background-color: rgb(207, 243, 6);
}

.blockCellCont .blockStats > div.warning {
  background-color: rgb(253, 197, 113);
}

.blockCellCont .blockStats > div.error {
  background-color: rgb(253, 113, 113);
}

.GRSchedule .changeActions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: yellow;
  padding: 5px 0;
}

.GRSchedule .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.ScheduleChangePopover {
  padding: 5px;
  font-size: 12px;
}

.ScheduleChangePopover .arrow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid black;
}

.ScheduleChangePopover .content {
  padding: 5px;
  width: 340px;
}

.ScheduleChangePopover .content > div {
  margin-bottom: 10px;
}

.ScheduleChangePopover .content > div:last-child {
  margin-bottom: 0;
}

.ScheduleChangePopover .MuiFormGroup-root label span {
  font-size: 10px !important;
}

.ScheduleChangePopover .MuiButtonBase-root {
  padding: 5px !important;
}

.ScheduleChangePopover .MuiFormControl-root {
  align-items: center !important;
  width: 100% !important;
}

.GRSchedule table .isDetailed th,
.GRSchedule table .hasPopover th,
.GRSchedule table th.hasPopover {
  border: 1px solid blue;
  font-weight: bold;
}

.GRSchedule table .Completed th {
  background-color: rgb(255, 160, 160) !important;
}

.GRSchedule table .Released th {
  background-color: rgb(172, 247, 162) !important;
}

.GRSchedule table .hasPopover td.hasPopover input {
  background: blue !important;
}

.dateCell .comment {
  width: 5px;
  height: 5px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: rgb(170, 30, 46);
}

.schedulehelp {
  background-color: #fff;
  border: solid 1px #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100vw;
  max-width: 300px;
  padding: 10px;
  font-size: 14px;
  line-height: 1;
}

.schedulehelp .colorexplainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.schedulehelp .rowx {
  display: flex;
  width: 100%;
  min-width: 0;
  border: solid 1px #e0e0e0;
  padding: 3px;
  align-items: center;
}

.schedulehelp .colorsquare {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.schedulehelp .explanation {
  min-width: 0;
  flex: 1;
}

.GRSchedule .BlockDetailCont {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  background-color: white;
  z-index: 1000;
  border-left: 1px solid rgb(238, 235, 235);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .blockCellCont {
    flex-direction: column;
    align-items: stretch;
  }

  .GRSchedule .ScheduleGrid table th:first-child {
    min-width: 220px;
    width: 220px;
    max-width: 220px;
  }
  .blockCellCont .blockStats {
    flex-direction: row;
    justify-content: space-between;
  }
}
