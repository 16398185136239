.sectionCont {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  flex-wrap: wrap;
}

.sectionCont div {
  background-color: lightgray;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.sectionCont div.selected {
  background-color: blue;
  color: white;
}

.farmCont {
  width: 100%;
  height: 100%;
  position: relative;
}

.farmCont svg {
  width: 100%;
  height: 100%;
  position: relative;
}
