.DemandSupplyDS.datesSlider {
  display: flex;
  gap: 5px;
  position: relative;
}

.horizontal-slider {
  height: 24px;
  width: 100%;
}

.horizontal-slider .mark {
  height: 7px;
  width: 1px;
  background: #000;
  padding: 0;
}

/* .bomRow {
  transform: translateX(10px);
} */

.horizontal-slider .thumb {
  position: relative;
  cursor: pointer;
  font-size: 10px;
  background-color: whitesmoke;
  padding: 1px 3px;
  border-radius: 5px;
  top: 5px;
  user-select: none;
}

.horizontal-slider .thumb.vintageTransition {
  background-color: rgb(211, 211, 211);
}

.horizontal-slider .thumb.bomPeriod {
  background-color: white;
  border: 1px solid #1976d2;
  color: #1976d2;
}

/*
.horizontal-slider .thumb.startDate {
  transform: translateX(50%);
} */

.horizontal-slider .thumb.active {
  outline: none;
}

.horizontal-slider .thumb .arrow {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  width: 3px;
  height: 3px;
  background-color: #000;
}

.horizontal-slider .track {
  height: 5px;
  background-color: #868686;
}
