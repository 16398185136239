.Blends .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.BOMBlendTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.BOMBlendTable th,
.BOMBlendTable td {
  border: 1px solid #ccc;
  padding: 5px;
}

.BOMBlendTable th {
  background-color: #f0f0f0;
}

.BOMBlendTable td {
  background-color: #f0f0f0;
}

.BOMBlendTable tr:nth-child(even) {
  background-color: #f0f0f0;
}

.BOMBlendTable tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.BOMBlendTable tr:hover {
  background-color: #e0e0e0;
}

.BOMBlendTable tr:hover td {
  background-color: #e0e0e0;
}

.BOMBlendTable tr:hover th {
  background-color: #e0e0e0;
}

.BOMBlendTable input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
