.gmap {
  width: 100%;
  height: 100%;
}

.blockMarker {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 11px;
  font-weight: 800;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
  flex-direction: column;
}

.blockMarker.notsigned {
  background-color: green;
  color: white;
}

.boundaryControl .MuiInput-input {
  font-size: 12px !important;
}
.boundaryControl .MuiAutocomplete-option {
  font-size: 12px !important;
}

.boundaryControl .MuiSvgIcon-root {
  color: black !important;
}
