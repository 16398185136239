.FilterController .button {
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  padding: 5px;
}

.filterBox {
  padding: 10px;
  max-width: 500px;
  width: 100vw;
}

.filterBox .filterItem {
  margin-bottom: 10px;
  display: flex;
}

.filterBox .filterItem > div:last-of-type {
  flex: 1;
}

.filterBox .filterTopBar {
  display: flex;
  justify-content: space-between;
}

.filterBox .filterItemLabel {
  max-width: 100px;
  min-width: 100px;
}
