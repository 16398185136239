.DemandSupply .ribbon {
  border-bottom: solid 1px rgb(228, 228, 228);
  margin-bottom: 0;
}

.DemandSupply .ribbon input {
  font-size: auto;
}

.DemandSupply .MuiAutocomplete-root {
  margin-top: 6px;
  margin-bottom: 5px;
}

.DemandSupply .MuiAutocomplete-root .MuiFormLabel-root {
  font-size: 12px;
}

.DemandSupply .cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.DemandSupply .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.DemandSupply .bodyCont {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  display: flex;
}

.DemandSupply .DemandItem .head {
  line-height: 1;
  margin-bottom: 3px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.DemandSupply .DemandItem .head .title {
  font-size: 16px;
  font-weight: bold;
  position: sticky;
  left: 0;
  background-color: white;
}

.DemandSupply .DemandItem .head .units {
  font-size: 10px;
  background-color: rgb(207, 207, 207);
  border-radius: 10px;
  padding: 3px 5px;
}

.DemandSupply .DemandItem {
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  font-size: 12px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
}

.DemandSupply .DemandList {
  padding-top: 5px;
  flex: 1;
}

.DemandSupply .Row {
  display: flex;
  border-bottom: solid 1px rgb(228, 228, 228);
}

.DemandSupply .vintages {
  display: flex;
}

.DemandSupply .vintages .vintageCol {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 40px;
  border-left: solid 1px rgb(228, 228, 228);
}

.DemandSupply .sku .vintages .vintageCol {
  justify-content: center;
}

.DemandSupply .blend .vintages .vintageCol.actual {
  font-weight: bold;
}

.DemandSupply .blend .vintages .vintageCol.bom {
  font-style: italic;
}

.DemandSupply .vintages .vintageCol * {
  text-align: center;
}

.DemandSupply .vintages .vintageCol .vintage {
  font-weight: 900;
  text-decoration: underline;
}

.DemandSupply .Row .left {
  display: flex;
  width: 400px;
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  border-right: solid 3px rgb(214, 214, 214);
}

.DemandSupply .Row.blend .left {
  cursor: pointer;
}

.DemandSupply .bot .Row .left,
.DemandSupply .bot .Row .right {
  background: rgb(245, 245, 245);
}

.DemandSupply .Row .left {
  padding-left: 3px;
}

.DemandSupply .Row .left .titleCont {
  flex: 1;
  padding: 3px 0;
}

.DemandSupply .bot .Row .left .title {
  text-align: right;
  padding-right: 5px;
}

.DemandSupply .Row .titleCont .MuiButtonBase-root {
  padding: 1px;
  min-width: 0;
  line-height: 1;
  font-size: 10px;
}

.DemandSupply .Row .titleCont .MuiButtonBase-root .MuiButton-icon {
  margin-right: 0;
}

.DemandSupply .Row.blend .left .title .blend {
  font-weight: 800;
  font-size: 14px;
}

.DemandSupply .Row.sku .left .title {
  padding-left: 10px;
}

.DemandSupply .Row.blend .left .title .type {
  margin-right: 5px;
}

.DemandSupply .Row .right {
  flex: 1;
  z-index: 1;
  display: flex;
}

.DemandSupply .Row .right .periods {
  flex: 1;
  display: flex;
}

.DemandSupply .Row .right .bomAndPeriods {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.DemandSupply .Row .right .periodRow {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: flex-end;
}

.DemandItem .periods .values {
  display: flex;
  justify-content: center;
}

.DemandItem .periods .yAxis {
  font-size: 8px;
  width: 26px;
  text-align: center;
  align-self: stretch;
  padding: 0 2px;
}

.noDemand {
  padding: 3px;
}

.DemandItem .Row.blend .periods .yAxis {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 20px;
}

.DemandSupply .Row .right .periods .period {
  font-size: 8px;
  width: 24px;
}

.DemandSupply .Row .right .periods .period .date {
  text-decoration: underline;
}

.DemandSupply .Row .right .periods .period .supplySources {
  display: flex;
  flex-direction: column;
}

.DemandSupply .nonelabel {
  padding: 3px;
}

.stacked-bar {
  display: flex;
  flex-direction: column-reverse;
}

.stacked-bar .segment {
  width: 100%;
}

.DemandSupply .Row .bomSection > * {
  margin-bottom: 5px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.EditBOM .bomPeriods {
  margin: 10px 0;
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.EditBOM .bomPeriod {
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 5px;
  display: flex;
  margin-bottom: 5px;
}

.EditBOM .bomPeriod .left {
  flex: 1;
}

.EditBOM .bomPeriod .dates {
  display: flex;
  gap: 10px;
}

.EditBOM .detailsCont .top {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.EditBOM .detailsCont .top button {
  padding: 2px 7px;
  border: none;
  line-height: 1;
  font-size: 10px;
  border-radius: 5px;
}

.EditBOM .detailsCont .detail {
  display: flex;
  align-items: center;
  gap: 5px;
}

.EditBOM .detailsCont .detail > * {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px;
}

.EditBOM .validationIssues {
  color: red;
  font-weight: bold;
}

.SingleBOMBalance {
  padding-right: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 140px;
}

.SingleBOMBalance > * {
  display: flex;
}

.SingleBOMBalance .label {
  width: 80px;
  margin-right: 5px;
}

.SingleBOMBalance .label {
  width: 80px;
  margin-right: 5px;
}

.SingleBOMBalance .value {
  flex: 1;
  text-align: right;
}
