.QueryPage {
  display: flex;
  flex-direction: column;
}

.QueryPage .ribbon {
  min-height: 1px;
  flex-shrink: 0;
}

.QueryPage .body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.QueryPage .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
