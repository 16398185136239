.GRDeliveries {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.GRDeliveries .ribbon {
  flex-shrink: 0;
}

.GRDeliveries .tableCont {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  overflow: auto;
  max-height: 100%;
}

.GRDeliveries table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  min-height: 0;
  max-height: 100%;
  white-space: nowrap;
  table-layout: fixed;
}

.GRDeliveries table thead tr th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: rgb(214, 208, 208);
  box-shadow: 4px 0 4px 0 rgb(97, 97, 97);
  text-align: center;
}

.GRDeliveries table tbody tr td {
  padding: 0.5rem;
}

.GRDeliveries table,
.GRDeliveries th,
.GRDeliveries td {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

.GRDeliveries td .error {
  background-color: red;
  color: white;
  text-align: center;
}

.GRDeliveries td .link {
  color: blue;
  cursor: pointer;
  text-align: center;
}

.GRDeliveries .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.GRDeliveries .BlockDetailCont {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 600px;
  background-color: white;
  z-index: 1000;
  border-left: 1px solid rgb(238, 235, 235);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

.GRDeliveries table button.edit {
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 10px;
}

.GRDeliveries th,
.GRDeliveries td {
  width: 100px;
}

.GRDeliveries th.program,
.GRDeliveries td.program {
  width: 140px;
}
.GRDeliveries td.editable > div {
  display: flex;
  justify-content: space-between;
}

.GRDeliveries th.wt,
.GRDeliveries td.wt {
  width: 150px;
}

@media (max-width: 768px) {
  .GRDeliveries th,
  .GRDeliveries td {
    width: 70px;
  }

  .GRDeliveries td.editable,
  .GRDeliveries th.editable {
    width: 130px;
  }

  .GRDeliveries th.wt,
  .GRDeliveries td.wt {
    width: 150px;
  }
}
