.Demand .cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.Demand .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Demand .bodyCont {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  display: flex;
}

.Demand .DemandItem .head {
  line-height: 1;
  margin-bottom: 3px;
  display: flex;
  gap: 10px;
  align-items: flex-end;

  position: sticky;
  left: 0;
  align-self: flex-start;
}

.Demand .DemandItem .head button {
  border: none;
  font-size: 10px;
}

.Demand .DemandItem .head .title {
  font-size: 16px;
  font-weight: bold;

  background-color: white;
}

.Demand .DemandItem .head .chip {
  font-size: 10px;
  background-color: rgb(207, 207, 207);
  border-radius: 10px;
  padding: 3px 5px;
}

.Demand .DemandItem {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Demand .DemandList {
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
}

.Demand .Forecast .forecastRow {
  display: flex;
  font-size: 10px;
}

.Demand .Forecast .forecastRow .month {
  width: 45px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Demand .Forecast .forecastRow .month .date {
  color: #c9c9c9;
}

.EditForecast .forecastRow {
  display: flex;
  font-size: 10px;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.EditForecast .forecastRow .month {
  text-align: center;
  padding: 3px;
}

.EditForecast .forecastRow input {
  display: flex;
  font-size: 10px;
  width: 60px;
}

.EditForecast .head {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.EditForecast .buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
